.menuList {
  list-style-type: none;
  margin: 0px;
  padding: 0;

  li {
    display: inline;

    a {
      color: black;
      text-decoration: none;
    }
    & > a,
    .dropdown {
      padding: 15px;
      display: inline-block;
    }

    .dropdown:hover {
      .dropdown-menu {
        display: block;
        top: 80%;
      }
    }

    .mobileDropdown {
      display: none;

      &.isOpened {
        display: block;
      }
    }
  }
}

.couponTextbox {
  width: 100%;
  max-width: 200px;
}

.gallery {
  column-count: 4;
}

@media (max-width: 992px) {
  .gallery {
    column-count: 3;
  }
}

@media (max-width: 768px) {
  .gallery {
    column-count: 2;
  }
}

@media (max-width: 576px) {
  .gallery {
    column-count: 1;
  }
}

.linkNoStyle {
  text-decoration: none !important;
}

.priceLabel {
  font-size: 20px;
  font-weight: bold;
  display: inline;
}

.compareAtPrice {
  font-size: 16px;
  text-decoration: line-through;
  display: inline;
  margin-left: 10px;
}

.qtyInput {
  width: 90px !important;
}
.headerContainer {
  background-color: white;

  .header {
    margin: 20px 0px;
  }

  .burgerToggle {
    float: right;
    display: none;
  }

  .mobileCartBtn {
    display: none;
  }

  .mobileSearchBar{
    display: none;
  }  

  @media screen and (max-width: 768px) {
    .mobileSearchBar{
      display: block;
    }

    .desktopSearchBar{
      display: none;
    }

    .socialIcons {
      display: none;
    }
    .burgerToggle {
      display: inline;
    }

    .menuList:not(.mobileShown) {
      display: none;
    }

    .menuList li {
      display: block;

      a:not(.dropdown-toggle),
      .dropdown {
        padding: 10px 20px;
      }
    }

    .mobileCartBtn {
      position: relative;
      display: initial;
      color: #1fb3a9;
      margin-top: 5px;
      margin-right: 25px;
      float: right;

      .badge {
        position: absolute;
        top: -10px;
        right: -20px;
      }
    }
  }
}

.socialIcons {
  float: right;
}

.desktopSearchBar {
  width: 100%;
  max-width: 380px;
  float: right;
  margin-right: 20px;
}

.btn-primary {
  background-color: #1fb3a9 !important;
  border-color: #1fb3a9 !important;
  font-weight: bold !important;
}

.btn-default {
  border: solid 1px gray;
}

.badge-primary {
  background-color: #1fb3a9 !important;
}

.iconPrimary {
  color: #1fb3a9 !important;
}

.linkPrimary {
  color: #1fb3a9 !important;
  text-decoration: none !important;
}

.textPrimary {
  color: #1fb3a9 !important;
}

.discountRow {
  font-size: 16px;
  color: #1fb3a9 !important;
}

.iconClickable {
  cursor: pointer;
}

.topBanner {
  background-color: #1fb3a9;
  text-align: center;
  color: white;
  padding: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
}

footer {
  background-color: #f0f0f0;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
}

body {
  background-color: #f7f7f7;
}

.pageContent {
  padding-top: 30px;
  color: #6d6d6d;
  min-height: 500px;
}

.homeRow {
  margin-bottom: 30px;
}

.productCard {
  .productTitle {
    color: black;
    text-decoration: none;
  }
}

.categoryCard {
  @media screen and (min-width: 992px) {
    .card-body {
      position: absolute;
      bottom: 0px;
      width: 100%;
      background-color: white;
      display: none;
    }

    &:hover {
      .card-body {
        display: block;
      }
    }
  }

  .categoryTitle {
    color: #1fb3a9;
    text-decoration: none;
  }
}

.smallCategoryCard {
  .card-body {
    padding: 0.75rem;
  }
  .categoryTitle {
    color: #1fb3a9;
    text-decoration: none;
    font-size: 16px;
  }
}

.favoriteMark {
  position: absolute;
  left: 10px;
  top: 10px;

  padding: 5px;
  font-weight: bold;
  border-radius: 20px;
  width: 35px;
  height: 35px;
  overflow: hidden;

  background-color: white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);

  span {
    color: #1fb3a9;
    display: inline;
  }
  transition: width 0.4s;

  &:hover {
    width: 155px;
  }

  img {
    margin-right: 5px;
    display: inline;
  }
}

.cartItem {
  td {
    vertical-align: middle;
  }

  td:first-child {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      display: block;
    }
  }
}

.albumUploadedFiles {
  .card {
    .btn {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}

.text-linethrough {
  text-decoration: line-through;
}

.img-responsive {
  width: 100%;
}

.wordpress-namespace a {
  color: #1fb3a9;
}

.fb-video {
  text-align: center;

  & > span {
    max-width: 500px !important;
  }
}

#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
  border: 0px;
}

.lds-default.loading-small {
  transform: scale(0.5);
  width: 40px;
  height: 40px;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  &.smallLoading {
    transform: scale(0.5);
  }
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #1fb3a9;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
